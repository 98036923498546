import React from "react";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';

const PopupWait = ({ show_popup }) =>  {
return(


        <ToastContainer position="middle-center" className="p-3" style={{ zIndex: 1  }}>
                <Toast
                className="d-inline-block m-1"
                show={show_popup}
                
                >
                <Toast.Header closeButton={false}>
                    <strong className="me-auto">Leticoin</strong>
                </Toast.Header>
                <Toast.Body>
                <Row>
                <Col xs='auto'>
                <Spinner size='sm'animation="border" variant='primary' role="status"><span className="visually-hidden"></span></Spinner>
                </Col>
                <Col>
                Veuillez patienter.<br/>Traitement de la demande en cours.
                </Col>
                </Row>
                </Toast.Body>
                
                </Toast>
        </ToastContainer>
        );
}
  
export default PopupWait;