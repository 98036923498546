import React from "react";
import Alert from 'react-bootstrap/Alert';

class NotFound extends React.Component {
  render() {
    return(
        <Alert key="warning" variant="warning">
          404
        </Alert>
      
    );
  }
}

export default NotFound;