import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom';

function NavList() {
  return (
    <>
    <Navbar id="sidebar" collapseOnSelect expand="lg"  bg="primary" data-bs-theme="dark">
      <Container fluid>
        <Navbar.Brand>Leticoin</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
        
            <NavLink to="/list/operationList">Liste Opérations</NavLink>
            <NavLink to="/pay/receiving">Recevoir</NavLink>
            <NavLink to="/pay/send">Envoyer</NavLink>
            <NavLink to="/pay/ScanCode">Test Html5Qrcode</NavLink>
            


          </Nav>
          <Nav>
            <Nav.Link>Test</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}

export default NavList;
