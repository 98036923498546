import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';

import React from "react";
import { useState, useEffect } from 'react';

import util from '../inc/Utils'
import urlApi from '../inc/UrlApi';

import { useLocation  } from "react-router-dom"; /*, useNavigate*/



const OperationList = () =>  {
 

//console.log("urlApi.operationsmonthownerid_url",urlApi.operationsmonthownerid_url);

  const location = useLocation();
  //const navigate = useNavigate();
  
  let customer_id = util.isNull(new URLSearchParams(location.search).get("customer_id"),"ba8086f4-0c28-11ee-8252-5ebe302cd670");
  let bcoin = util.isNull(new URLSearchParams(location.search).get("bcoin"),"1");
  

  const [data, setData] = useState(null);
  //const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  let today = new Date();
  let dateB = new Date(new Date().setDate(today.getDate() - 30));

  let today_str= util.timestampFormat(today,"en",false).replaceAll("/","-") ;
  let dateB_str= util.timestampFormat(dateB,"en",false).replaceAll("/","-") ;
  
  const [dateD, setDateD] = useState(dateB_str);
  const [dateF, setDateF] = useState(today_str);
 
  const searchOpList = async (event) => {
    event.preventDefault();  

    console.log("fetchData 1");
    const requestOptions = {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
  };
  
  let dated_d="";
  let dated_f="";

  

  //console.log("today",today.valueOf(),"dateB",dateB.valueOf());  
 // console.log("today",today_str,"dateB",dateB_str);
 // setDateD(dateB_str);
 // setDateF(today_str);

  let dated=document.getElementById("date_d");
  let datef=document.getElementById("date_f");
  let dated_f2="";
  //console.log("datef",datef)
  if(dated!=null){ 
    //console.log("valueAsDate, valueAsNumber",dated.valueAsDate,dated.valueAsNumber);
    dated_d = dated.valueAsNumber;
  }
    //document.getElementById("mois_d").valueAsNumber
  if(datef!=null){ 
    //console.log("valueAsDate, valueAsNumber",datef.valueAsDate,datef.valueAsNumber);
    dated_f = datef.valueAsNumber;
    dated_f2 = new Date(dated_f);
    dated_f2.setDate(dated_f2.getDate()+1);
    dated_f2 = dated_f2.getTime();
    //console.log("dated_f2",new Date(dated_f2));
  }
   

    //let url="http://172.22.23.22:8000/operationsownerid/"+customer_id+"/"+bcoin;
    //let url="http://172.22.23.22:8000/operationsmonthownerid/"+customer_id+"/"+bcoin+"/"+(moisF===''?0:moisF)+"/"+(moisD===''?0:moisD);
    let url=urlApi.operationsmonthownerid+"/"+customer_id+"/"+bcoin+"/"+(dated_d===''?0:dated_d)+"/"+(dated_f2===''?0:dated_f2);
    console.log("url",url);
    fetch(url,requestOptions)
    

    .then(async response => {
      
      
      const data = await response.json();
      console.log("data",data); 
     
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      
      

      if( data.status === "200" || data.status === "201" ){
        setData({...data});
        //console.log("data.operations",data.operations.length);
        //setLoading(false);
        setError("");
      }else{
        //setLoading(false);
        setError(data.message);
      }
      
  })
  .catch(error => {
    console.log("error fetchData",error);
    //setLoading(false);  
    setError(error.toString());
     
  })
  //.finally(() => { alert('ici'); } )
  ;


  };
/*
  useEffect(() => {
    //document.getElementById("date_d").valueAsDate = new Date();
    
      
  },[]);
*/



  /*if (loading) {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (<Container><div style={style}><Spinner animation="border" role="status">
    <span className="visually-hidden">En cours de chargement ...</span>
  </Spinner><span>En cours de chargement ...</span></div></Container>);

  }*/


  /*if (error!=='') {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (<Container><br/><div style={style}><Alert variant="danger">
      {error}
    </Alert></div></Container>);

  }*/

  //if(data!==null){

    
    

      return (
        <div>
            
            

                      

            <Form onSubmit={searchOpList}>
    
            <Container fluid>
            <h1>&#x2637;&nbsp;Liste des opérations</h1>


            <Card border='secondary'>
            <Card.Header className='bs-primary-rgb'>Recherche</Card.Header>
            <Card.Body>
            <Card.Subtitle>Selectionner une période glissante</Card.Subtitle>
                <Row>
                  <Col xs={6} className="d-flex g-1">Du</Col>
                  <Col xs={6} className="d-flex g-1">Au</Col>
                </Row>
                <Row>
                  <Col xs={6} className="d-flex g-1">
                  <Form.Control
                    //type="number"
                    type="date"
                    name="date_d"
                    id="date_d"
                    defaultValue={dateD}
                    //onChange={(e) => setMoisD(e.target.value)}
                  />
                  </Col>
                  <Col xs={6} className="d-flex g-1">
                  <Form.Control
                    //type="number"
                    type="date"
                    name="date_f"
                    id="date_f"
                    defaultValue={dateF}
                    //onChange={(e) => setMoisF(e.target.value)}
                  />
                  </Col>
                </Row>
                {/*<Row>
                  <Col>
                  <Button variant="primary" type="submit">
                    Rechercher
                  </Button>
                  </Col>
              </Row>*/}
<br/>
<Row>
            <Col className='text-center'>
            <Button variant="primary" type="submit">
                    Rechercher
                  </Button>
                </Col>
             
            </Row>

            </Card.Body>
          </Card>
              <br/>

            <Table striped bordered hover responsive>
            <thead>
                <tr style={{backgroundColor:'#C0C0C0'}}>
                {["Opération","Id de transaction","Montant","Commission","Date"].map((l) => {
               return <th>{l}</th>
            })}
                
                
                </tr>
            </thead>
            <tbody>
            {data!==null && data.operations.length!==undefined && data.operations.map((v, i) => (
            <tr>
                  <td>{  (v.operation===1?<><span style={{color:'green',fontSize:'20px'}}>&#8680;</span><span>Reception</span></>:<><span style={{color:'red',fontSize:'20px'}}>&#8678;</span><span>Depense</span></>)  }</td><td>{v.txid}</td><td>{v.amount}</td><td>{v.fee}</td><td>{util.timestampFormat(v.timestamp,"fr",true)}</td>
            </tr>
            ))}
            </tbody>
            { (data!==null && data.operations.length===0?<tr><td colSpan={4}>Pas de résultat.</td></tr>:"")  }
            </Table>
    
            {error!==''?
               <div><Alert variant="danger">{error}</Alert></div>:"" }



            </Container>
    
            </Form>
    
        </div>
      );
    //}
  }
  
  export default OperationList;