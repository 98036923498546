import React, {useRef} from "react";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState  } from 'react'; 

const PopupRetour = ({ bgt_popup, titre_popup, msg_popup, show_popup }) =>  {
let delay=5000;
const [showPopup, setShowPopup] = useState(show_popup);
console.log("PopupRetour",show_popup,showPopup);

const closePopup = () => {setShowPopup(false);}

React.useEffect(() => {
    setShowPopup(show_popup);
  }, [show_popup]); 

let style_color_body = "green";
let icon = <>&#10004;</>;
if(bgt_popup==='danger'){
    style_color_body="red";
    icon=<>&#10006;</>;
}

return(
<Row>
        <Col xs={6}>
        <ToastContainer position="middle-center" className="p-3" style={{ zIndex: 1 }}>
                <Toast
                className="d-inline-block m-1"
                //bg={bgt_popup}
                delay={delay}
                onClose={() => closePopup()} show={showPopup}
                autohide
                
                >
                <Toast.Header>{/* style={{backgroundColor: style_color_body,color:'white'}}*/}
                    <strong className="me-auto">{titre_popup}</strong>
                    <small>{delay/1000} secondes</small>
                </Toast.Header>
                <Toast.Body>{/*style={{backgroundColor: style_color_body, opacity:0.5,color:'white' }}*/}
                    <span style={{color:style_color_body, fontSize:'20px' }}>{icon}</span>&nbsp;{msg_popup}
                </Toast.Body>
                </Toast>
        </ToastContainer>
        </Col>
      </Row>);
}
  
export default PopupRetour;