import React from "react";

class Footer extends React.Component {
  render() {
    return(
      <span><br/><br/><br/><footer className='color-footer pt-2'>
        Copyrigth
      </footer></span>
    );
  }
}

export default Footer;