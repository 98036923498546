// file = Html5QrcodePlugin.jsx
import { Html5Qrcode , Html5QrcodeScanType    } from 'html5-qrcode';
import { useEffect, useRef,useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';


const qrcodeRegionId = "html5qr-code-full-region";

let html5QrCode;



const QrCodeScan = (props) => {
    //const fileRef = useRef(null);
    const [cameraList, setCameraList] = useState([]);
    const [activeCamera, setActiveCamera] = useState();
    const [statusScan, setStatusScan] = useState(false);
    const [statusCam, setStatusCam] = useState(false);
    const [errQr, setErrQr] = useState('');
    const [errQrMob, setErrQrMob] = useState('');

    const debug_mob = false;
    
    console.log("props",props);

    const config = { fps: props.fps, qrbox: { width:props.qrbox , height:props.qrbox } }; //, useBarCodeDetectorIfSupported: true, rememberLastUsedCamera: true

    useEffect(() => {
    
        html5QrCode = new Html5Qrcode(qrcodeRegionId, {verbose: false, formatsToSupport: Html5QrcodeScanType.QR_CODE }); 
        getCameras();
        const oldRegion = document.getElementById("qr-shaded-region");
        oldRegion && oldRegion.remove();

        // cleanup function when component will unmount
        return () => {
          console.log("cleanup");  
          try {
                html5QrCode
                  .stop()
                  .then((res) => {
                    html5QrCode.clear();
                    setStatusCam(false);
                  })
                  .catch((err) => {
                    console.log("Cleanup catch",err.message);
                    if(debug_mob){
                      setErrQrMob("Cleanup catch :" + err.message);
                    }

                  });
              } catch (err) {
                console.log("Cleanup catch 2",err);
                if(debug_mob){
                  setErrQrMob("Cleanup catch 2"+err);
                }
                
              }
        };
    }, []);


    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      console.info(decodedResult, decodedText);
      props.onResult(decodedText,decodedResult);
      setStatusScan(decodedText)
      //alert(`decoded:__ ${decodedText}`);
      //alert(decodedText);
      stopScan();
    };

    const qrCodeErrorCallback = (error) => {
      console.log("qrCodeErrorCallback error ",error);
      if(debug_mob){
        setErrQrMob("qrCodeErrorCallback error"+error);
      }
    };

      const startScan = () => {
        
        try{
        html5QrCode
        //.start(activeCamera.id, config, qrCodeSuccessCallback, qrCodeErrorCallback  ) // { facingMode: "environment" }, 
        .start({ facingMode: "environment" }, config, qrCodeSuccessCallback, qrCodeErrorCallback  ) // { facingMode: "environment" }, 
        .then(() => {
          //alert("startScan "+activeCamera.id);
          setStatusCam(true);
        });

      }catch(e){
        console.log("startScan:",e);
        if(debug_mob){
          setErrQrMob("startScan:"+e);
        }
      }

      }

      const stopScan = () => {
        try {
          html5QrCode
            .stop()
            .then((res) => {
              html5QrCode.clear();
              setStatusCam(false);
            })
            .catch((err) => {
              console.log("stopScan:",err.message);
              if(debug_mob){
                setErrQrMob("stopScan:"+err.message);
              }
            });
        } catch (err) {
          console.log("stopScan catch:",err);
          if(debug_mob){
            setErrQrMob("stopScan :"+err);
          }
        }
      };
      
    const getCameras = () => {
        Html5Qrcode.getCameras()
          .then((devices) => {
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            //console.info(devices);
            //alert(JSON.stringify(devices));
            if (devices && devices.length) {
              setCameraList(devices);
              setActiveCamera(devices[0]);
            }
          })
          .catch((err) => {
            console.log("getCameras catch:",err);
            if(debug_mob){
              setErrQrMob("getCameras catch:"+err);
            }
            setCameraList([]);
            //setErrQr(err);
          });
      } ;

    return (
      <>
      <Row>

         <Col className='text-center mt-1'>
        <Row>
            <Col xs={6}  className='d-grid'>
              <Button variant='outline-primary' onClick={() => startScan()}>Scan</Button>
            </Col>
            <Col xs={6}  className='d-grid'>
              <Button variant='outline-danger' onClick={() => stopScan()}>Stop</Button>
            </Col>
        </Row>
      </Col>
      </Row>
      <Row>
        <Col className='text-center mt-1'>
        { statusScan && statusScan!==''? <Alert variant='primary'>
            Information(s) récupérée(s):<br/>
            {statusScan}
        </Alert>:'' }

        { errQr && errQr!==''? <Alert variant='danger'>
            {errQr}
        </Alert>:'' }

        { errQrMob && errQrMob!==''? <Alert variant='danger'>
            {errQrMob}
        </Alert>:'' }

        {cameraList.length === 0 && (
            <Alert variant='danger'>Pas de caméra activée ou trouvée</Alert>
        )}

        </Col>
      </Row>
      <Row>
      <Col>
        <Row className="justify-content-md-center">
        <Col xs={12} md={6} className='text-center'>
        <Card>
            <Card.Header>Caméra</Card.Header>
            <Card.Body>
            <div id={qrcodeRegionId}></div>
            </Card.Body>
        </Card>
        </Col></Row>
        </Col>
      </Row>
      </>

    );
};

export default QrCodeScan;