import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import CountDown from '../inc/CountDown';

import React from "react";


const payment2 = () =>  {
    
      return (
        <div>
            
            <h1>Payment</h1>
            <h3>Réglèment en crypto-monnaie</h3>

            <Alert key="success" variant="success">
                Temps restants pour valider la transaction :<br/>
                <CountDown/>
            </Alert>          

            <Form>
    
            <Container>
                  

            <Row>

              <Col>

                <Card>
                    <Card.Body>
                      <Card.Title>Information de la transaction</Card.Title>
                      <Card.Text>
                      <Row>
                        <Col>Total de la commande : </Col><Col>XX</Col>
                        </Row>
                       <Row>
                        <Col>Adresse de réglement : </Col><Col>XXXXXXXXXXXXXXXXXX</Col>
                      </Row>
                      <br/>
                      <Row>
                        <Col>Veuillez scanner le QR Code suivant : </Col>
                      </Row>
                      <Row>
                            <Col>"Le QR"</Col>                        
                      </Row>
                        <br/>
                        <br/>
                      </Card.Text>
                  
                    </Card.Body>
                  </Card>
                    

                

                </Col>
        

      

              <Col>

              <Card>
                  <Card.Body>
                    <Card.Title>Remarque important</Card.Title>
                    <Card.Text>
                      <b>Instruction</b><br/>
                        La confirmation du réglement peut prendre 20 minutes ou plus après avoir scanné le QR Code.
                        <br/>
                        <br/>
                        Vous pouvez quitter cet écran sans attendre la confirmation, un mail vous sera envoyé lorsque le réglement sera définitivement validé.
                        <br/>
                        <br/>                        
                    </Card.Text>
                
                  </Card.Body>
                </Card>


              </Col>

            </Row>    
            <br/>
            <Row>

            <Col>
            
            
                <Button variant="primary" type="submit">
                    Revenir au site marchant
                </Button>
           </Col>
           <Col>
                <Button variant="primary" type="submit">
                  Revenir au panier
                </Button>
             
                </Col>
             
            </Row>
    
    
            </Container>
    
            </Form>
    
        </div>
      );
    
  }
  
  export default payment2;