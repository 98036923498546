import './css/leticoin.css'
import React from "react";
import PaymmentWithQr from './pay/payment';
import PaymmentWithoutQr from './pay/payment2';
import ConfirmPin from './pay/confirmPin';
import OperationList from './list/operationList'
import Receiving from './pay/receiving'
import Send from './pay/send'
import ScanCode from './pay/ScanCode'
import NotFound from './inc/NotFound';

import { Routes, Route } from "react-router-dom";

/*// Create the function
export function AddLibrary(urlOfTheLibrary) {
  const script = document.createElement('script');
  script.src = urlOfTheLibrary;
  script.async = true;
  document.body.appendChild(script);
}*/

class App extends React.Component {
  render() {
    return (

      <Routes>
        <Route exact path="/pay/payment/create" element={<PaymmentWithQr />} />
        <Route exact path="/pay/payment/create2" element={<PaymmentWithoutQr />} />
        <Route exact path="/pay/payment/confirmPin" element={<ConfirmPin />} />
        <Route exact path="/list/operationList" element={<OperationList/>}></Route>
        <Route exact path="/pay/receiving" element={<Receiving/>}></Route>
        <Route exact path="/pay/send" element={<Send/>}></Route>
        <Route exact path="/pay/ScanCode" element={<ScanCode/>}></Route>
        
        

        <Route path="*" element={<NotFound />} />

        

      </Routes>

    );
  }
}

export default App;
