import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
import Spinner from 'react-bootstrap/Spinner';

import { useState, useEffect } from 'react';

import util from '../inc/Utils';  
import urlApi from '../inc/UrlApi';

import {QRCodeSVG} from 'qrcode.react';

import { useLocation  } from "react-router-dom"; /*, useNavigate*/

const Receiving = () =>  {
 
  const location = useLocation();
  //const navigate = useNavigate();
  
  let customer_id = util.isNull(new URLSearchParams(location.search).get("customer_id"),"ba8086f4-0c28-11ee-8252-5ebe302cd670");
  let coin  = util.isNull(new URLSearchParams(location.search).get("coin"),"1");
  

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [label, setLabel] = useState("");
  const [montant, setMontant] = useState("");
  const [qrcode, setQrcode] = useState("");
  const [error, setError] = useState('');
  

  const [values, setValues] = useState({ });
  const [formerrors, setFormErrors] = useState({}); 

  const handleChange = (event) => {
      
    setValues((values) => ({
      ...values,
    [event.target.name]:  event.target.value,
    }));
    //console.log("event.target",event.target);
  }

   const validate = () => {
      console.log("Validate the form....");
  
      let errors = {};
    console.log("values",values,!values.amount);

      if (!values.address) {
        errors.address = "Veuillez saisir une adresse";
      }

      if (!values.label) {
        errors.label = "Veuillez saisir un libellé";
      }else{
        setLabel(values.label);
      }

      if (!values.amount) {
        errors.amount = "Veuillez saisir un montant";
      }else{
        setMontant(values.amount);
      }
      
      //console.log("checkedItems",checkedItems);
      console.log("errors",errors);
      setFormErrors(errors);
  
      if (Object.keys(errors).length === 0) {
        return true;
      } else {
        return false;
      }
    };
  
    const handleSubmit = (event) => {
      if (event) event.preventDefault();
      
      var r = validate(values);
      console.log("handleSubmit r",r);
      if(r){
        receive_qrcode();
      }
      return(r);
    };

  const fetchData = async (customer_id,coin) => {
      
    console.log("fetchData 1");
    const requestOptions = {
      mode: 'cors',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
  };
    
  
  //let url="http://172.22.23.22:8000/walletnextavailableaddress/"+customer_id+"/"+coin;  
  let url=urlApi.walletnextavailableaddress+"/"+customer_id+"/"+coin;
    console.log("url",url);
    fetch(url,requestOptions)
    
    .then(async response => {
      
      const data = await response.json();
      console.log("data",data); 
     
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      

      if( data.status === "200" || data.status === "201" ){
        setData({...data});
        setValues({address:data.address});
        setLoading(false);
        setError("");
        document.getElementById("recup_adr").style.display = "none";
      }else{
        setValues({address:""});
        setLoading(false);
        setError(data.message);
      }
      
  })
  .catch(error => {
    console.log("error fetchData",error);
    setValues({address:""});
    setLoading(false);  
    setError(error.toString());
  })
  //.finally(() => { alert('ici'); } )
  ;


  };

  useEffect(() => {
    fetchData(customer_id,coin);
    
  }, [customer_id,coin]);


  const receive_qrcode = async () => {
    console.log("receive_qrcode");
    const requestOptions = {     mode: 'cors',     method: 'GET'   };
    
    
    //let url="http://172.22.23.22:8000/receiveqrcode/"+data.address+"/"+values.amount+"/"+values.label;
    let url=urlApi.receiveqrcode+"/"+data.address+"/"+values.amount+"/"+values.label;
    //console.log("url",url,"recipient_adress",data.recipient_address,"amount",data.amount_crypto_base);
    console.log("url",url);
    fetch(url,requestOptions)
    

    .then(async response => {
      
      
      const data_t = await response.json();
      console.log("data_t",data_t); 
     
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      
      

      if( data_t.status === "200" || data_t.status === "201" ){
        setQrcode(data_t.address);
      }
      
  })
  .catch(error => {
    console.log("error callTransact",error);
    
     
  })
  //.finally(() => { alert('ici'); } )
  ;


  };

 /* if (loading) {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (<Container><div style={style}><Spinner animation="border" role="status">
    <span className="visually-hidden">En&nbsp;cours&nbsp;de&nbsp;chargement&nbsp;...</span>
  </Spinner></div></Container>);

  }*/

      return (
        

<Container fluid>
          <Form onSubmit={handleSubmit}>
    
            

            <Row>
              <Col className='sub_nav'>
              <h1>&#8681;&nbsp;Réception</h1>
              </Col>
            </Row>

          
            <Row><Col xs={12} sm={8}>
            
            <Form.Label htmlFor="adresse">Adresse <Button id='recup_adr' variant="primary"  size="sm" disabled><Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"/>
              Récupération adresse...</Button></Form.Label>
            <InputGroup className="mb-3">
                 {/*<InputGroup.Text id="basic-addon1">@</InputGroup.Text>*/}
                <Form.Control
                placeholder="Saisir une adresse"
                name="address"
                id="address"
                defaultValue={data && util.isNull(data.address,"")}
                isInvalid={ !!formerrors.address } onChange={handleChange}
                />
                {formerrors.address && (<Form.Control.Feedback type="invalid">
                        {formerrors.address}
                    </Form.Control.Feedback>)}
                
            </InputGroup>
            
            
            {error!==''?
            <div><Alert variant="danger">
              {error}
            </Alert></div>
            :""}

           

            <Form.Label htmlFor="label">Libellé</Form.Label>
            <InputGroup className="mb-3">
                {/* <InputGroup.Text id="basic-addon1">[A-Z/1-9]</InputGroup.Text>*/}
                <Form.Control
                placeholder="Saisir un libellé"
                name="label"
                id="label"
                //defaultValue={util.isNull(data.label,"")}
                isInvalid={ !!formerrors.label } onChange={handleChange}
                />
                {formerrors.label && (<Form.Control.Feedback type="invalid">
                        {formerrors.label}
                    </Form.Control.Feedback>)}
            </InputGroup>

            <Form.Label htmlFor="montant">Montant</Form.Label>
            <InputGroup className="mb-3">
                {/* <InputGroup.Text id="basic-addon1">[1-9]</InputGroup.Text> */}
                <Form.Control
                type="number"
                placeholder="Saisir un montant"
                name="amount"
                id="amount"
                //defaultValue={util.isNull(data.amount,"")}
                isInvalid={ !!formerrors.amount } onChange={handleChange}
                />
                {formerrors.amount && (<Form.Control.Feedback type="invalid">
                        {formerrors.amount}
                    </Form.Control.Feedback>)}
            </InputGroup>

            </Col>
            <Col>
            <div className='text-center'>
            <QRCodeSVG
            value={qrcode}
            size='200' 
            imageSettings={{
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              excavate: true,
              }}
        />
        </div>
            </Col>
            </Row>
              
              
            <Row>
            <Col className='text-center d-grid mt-2'>
            <Button variant="primary" type="submit">
                  Recevoir
                </Button>
              </Col>
            </Row>
    
            

            
      </Form>
      </Container>
    
        
      );
   
  }
  
  export default Receiving;