function isNull(val,replaceVal){
    //console.log("isNull",(val ===undefined || val === null));
    return (val ===undefined || val === null ? replaceVal : val);     
}

function toInt( val  ){
    let r = "";
    if(val !==undefined || val !== null){
        try{
            r= parseInt(val,10);
        }catch(e){ console.log(val+" n'est pas un int");  }
    }
    return r;
}

function timestampFormat(timestamp,fmt,withTime){
var date = new Date(timestamp);
var year    = date.getFullYear();
var month   = date.getMonth()+1;
if((month)<10) month = "0"+(month);
var day     = date.getDate();
if(day<10) day = "0"+day;
var hour    = date.getHours();
if(hour<10) hour = "0"+hour;
var minute  = date.getMinutes();
if(minute<10) minute = "0"+minute;
var seconds = date.getSeconds();
if(seconds<10) seconds = "0"+seconds;  
let str = "";
if(fmt==="fr"){
    str=day+"/"+month+"/"+year;
}else{
    str=year+"/"+month+"/"+day
}
str+=(withTime?" "+hour+":"+minute+":"+seconds:"")
return str;
}

function msg_status_transact(){
    const msg_status_transact = [];
    msg_status_transact[0]="Initialisé";
    msg_status_transact[1]="En cours de traitement";
    msg_status_transact[2]="Ok";
    msg_status_transact[3]="Expiré";
    msg_status_transact[4]="En erreur";
    return msg_status_transact;
}

function get_msg_status_transact(id){
    let tab = msg_status_transact();

    return(tab[id]);
}

module.exports.isNull = isNull;
module.exports.timestampFormat = timestampFormat;
module.exports.msg_status_transact = msg_status_transact;
module.exports.get_msg_status_transact = get_msg_status_transact;
module.exports.toInt = toInt;