import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import CountDown from '../inc/CountDown';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import React from "react";
import { useState, useEffect  } from 'react'; //, useCallback,  useRef

import util from '../inc/Utils'
import urlApi from '../inc/UrlApi';

import {QRCodeSVG} from 'qrcode.react';

import { useLocation  } from "react-router-dom"; /*, useNavigate*/

const Payment = () =>  {
 
const location = useLocation();
//const navigate = useNavigate();
  
let payment_ref_id = util.isNull(new URLSearchParams(location.search).get("payment_ref_id"),"12345");
  
let max_call_status=10;
let redirect_ms=15000;//5000


const [data, setData] = useState(null);
const [errPin, setErrPin] = useState("");
const [loading, setLoading] = useState(true);
const [error, setError] = useState('');
const [listUrl,setlistUrl] = useState( {
  "details" : 
   [
    { url:"","text":""},
    { url:"","text":""},
    { url:"","text":""}
   ]
}    )

const [etatTraitement,setEtatTraitement] = useState( { id:-1, lib:"", disabledBtnRg:false, disabledBtnCart:false   }  );

const [showModalPin, setShowModalPin] = useState(false);
const [redirect, setRedirect] = useState( {url:"", url_text:"" , show:false }   );



const redirectUrlC = () => {
 console.log("redirectUrlC",redirectUrlC);
  setRedirect(  {url:listUrl.details[2].url, url_text:listUrl.details[2].text , show:true }  );
};
const redirectShowF = () => {
    console.log("redirectShowF",listUrl.details[1].url);
    setRedirect(  {  show:false  }   );
//    console.log("listUrl.details[1].url",listUrl.details[1].url);
    window.location.href = listUrl.details[1].url;
  }

  

  function RedirectPage() {

  
    return (
      <Row>
        <Col xs={6}>
        <ToastContainer position="middle-center" className="p-3" style={{ zIndex: 1 }}>
          <Toast bg="Light" onClose={() => redirectShowF()} show={redirect.show} delay={redirect_ms} autohide>
            <Toast.Header>
              <strong className="me-auto">Leticoin</strong>
              <small>{redirect_ms/1000} secondes</small>
            </Toast.Header>
            <Toast.Body>Vous aller être redirigé vers {redirect.url_text}.</Toast.Body>
          </Toast>
          </ToastContainer>
        </Col>
      </Row>
    );
  }


  /* Transaction - debut 

  INITIATED=0
  PENDING=1
  SUCCESS=2
  EXPIRED=3
  FAILED=4*/

  let retryCount=0;
  function transactionstatus(data){
    
    console.log("retryCount",retryCount);

    if(retryCount>=max_call_status){
      console.log("Nombre d'appel atteint.");
      return;
    }
    
    if(data!==null){
    console.log("transactionstatus");
      let p = data.recipient_address+"/"+data.crypto;
      
      const requestOptions = { mode: 'cors',  method: 'GET' };
      
      let url=urlApi.updatedtransactionstatus;//"http://172.22.23.22:8000/updatedtransactionstatus";
      url+="/"+p;
      console.log("url",url);
      fetch(url,requestOptions)
 
      .then(async response => {

        const data_j =  await response.json();
        console.log("data_j",data_j); 
       
        if( data_j.status === "200" ){
          setEtatTraitement( { id:data_j.tx_status, lib:util.get_msg_status_transact(data_j.tx_status),disabledBtnCart:true});
          if(data_j.tx_status===0){
            //setInterval(() => {
            console.log("retryCount "+data_j.status,retryCount);
            if(retryCount===0){
              transactionstatus(data,retryCount++);
            }else{
              setTimeout(() => {  transactionstatus(data,retryCount++); },5000);
          }
          }else{
            setEtatTraitement( { id:data_j.tx_status, lib:util.get_msg_status_transact(data_j.tx_status)});
            setRedirect(  {url:listUrl.details[0].url, url_text:listUrl.details[0].text , show:true }  );
          }
        }else{
          if( data_j.status === "500" ){
            setEtatTraitement( { id:4, lib:util.get_msg_status_transact(4),disabledBtnRg:true});
          }
        }
    })
    .catch(error => {
      console.log("error transactionstatus",error);
      setEtatTraitement( { id:4, lib:util.get_msg_status_transact(4)});
    })
    ;
  }
 }

  
  const handleCloseModalPin = () => {setShowModalPin(false); };
  
  const handleOkModalPin = () => {
    console.log("handleOkModalPin");
      let p = data.customer_id;
      for(var i=1;i<=6;i++){
        var val = document.getElementById("pin"+i).value;
        val =  (val.length===0?"0":val);
        p+="/"+val;
      }                            
      console.log("p",p);

      const requestOptions = {
        mode: 'cors',
        method: 'GET',
        //headers: { 'Content-Type': 'application/json' },
        
    };
    
      //let url="http://172.22.23.22:8000/checkownerpin";
      let url=urlApi.checkownerpin;
      url+="/"+p;
      fetch(url,requestOptions)
      
      .then(async response => {
        
        
        const data_j = await response.json();
        console.log("data_j",data_j); 
       
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = (data_j && data_j.message) || response.status;
            return Promise.reject(error);
        }

        if( data_j.status === "200" ){
          setShowModalPin(false);
          //handleShowRedirect();
          //setEtatTraitement('En cours de traitement'); remplacer par setEffect
          callTransact();
        }else{
          setErrPin(data_j.message);
        }
        
    })
    .catch(error => {
      console.log("error handleCloseModalPin",error);
      setErrPin(error.toString());
      
       
    })
    //.finally(() => { alert('ici'); } )
    ;
    
    
    

  }

  const selectPin = (event) => {
    const f = event.target;
    f.select();
  }

  const codePinChangeHandler = (event) => {
    const element = event.target;
    //console.log("element",element);
    const next = document.getElementById("pin"+(parseInt(element.id.replace("pin",""),10)+1));
    //console.log("next",("pin"+(parseInt(element.id.replace("pin",""),10)+1)),next);
    next ? next.focus() : element.blur();

    let cpt=0;
    for(let i=1;i<=6;i++){
      cpt += (parseInt( (document.getElementById("pin"+i).value!==''?1:0),10));
    }
    //console.log("cpt",cpt);
    if(cpt===6){
      handleOkModalPin();
     
    }

  };

  

  const handleShowModalPin = () => {setShowModalPin(true);}
  //const [dataPin, setDataPin] = useState(null);

  function ModalCodePin(){
    return (
      <>
        
  
        <Modal centered show={showModalPin} onHide={handleCloseModalPin}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation Code PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row>
            {[1,2,3,4,5,6].map((l) => {
               return <Col><InputGroup className='mb-1'>
               <Form.Control
                 type="text"
                 id={'pin'+l}
                 maxLength={1}
                 onFocus={(event) => selectPin(event)}
                 onChange={(event) => codePinChangeHandler(event)}
               />
             </InputGroup></Col>
            })}
            </Row>
            {errPin  &&
            <Row>
              <Col>
              <br/>
              <Alert variant="danger">
              {errPin}
              </Alert>
              </Col>
            </Row>
          }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary"  type="button" onClick={handleOkModalPin}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }


  function AffQr() {
    
    if(data.customer_has_wallet){
        return null
    }
    return (
      <span className='text-center'><br/>
      <Row>
        <Col>Veuillez scanner le QR Code suivant : </Col>
      </Row>
      <Row>
            <Col>
            <QRCodeSVG
            value={data.qr_code}
            size='200' 
            imageSettings={{
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              excavate: true,
              }}
        />
            </Col>                        
      </Row>
        </span>
      );
    
  }

  function EtatReglement(){
    if (etatTraitement.id===-1) {
      return null;
    }else{
      if (etatTraitement.id===0) {
        return <div>Etat du réglement : <span><><Spinner size='sm'animation="border" variant='primary' role="status"><span className="visually-hidden"></span></Spinner>&nbsp;<span id='etat_reglament'>{etatTraitement.lib}</span></></span></div>;
      }else{
        if (etatTraitement.id===3 || etatTraitement.id===4) {
          return <Alert variant="danger">{etatTraitement.lib}</Alert>;
        }else{
          return <div>Etat du réglement : {etatTraitement.lib}</div>;
        }
      }
    }
  }
  

  const redirectUrl = (opt) => {
  //alert("opt=>"+opt);
    window.location.href = listUrl.details[opt].url;
  };


  const callTransact = async () => {
    console.log("callTransact");
    setEtatTraitement( { id:0, lib:util.get_msg_status_transact(0), disabledBtnRg:true, disabledBtnCart:true  });
    const requestOptions = {
      mode: 'cors',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        "recipient_address": data.recipient_address,
        "amount": data.amount_crypto_base                      
       })
  };
    
  
    //let url="http://172.22.23.22:8000/transactionandbroadcast/"+data.public_extended_key;
    let url=urlApi.transactionandbroadcast+"/"+data.public_extended_key;
    console.log("url",url,"recipient_adress",data.recipient_address,"amount",data.amount_crypto_base);
    fetch(url,requestOptions)
    

    .then(async response => {
      
      
      const data_t = await response.json();
      console.log("data_t",data_t); 
     
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      
      

      if( data_t.status === "200" || data_t.status === "201" ){
        if( data.customer_has_wallet){
          transactionstatus(data);
        }
      }else{
        console.log("Error ws transactionandbroadcast");
        setEtatTraitement( { id:4, lib: util.get_msg_status_transact(4), disabledBtnRg:true } );
      }
      
  })
  .catch(error => {
    console.log("error callTransact",error);
    
     
  })
  //.finally(() => { alert('ici'); } )
  ;


  };

  
  


  useEffect(() => {
    const fetchData = async () => {
      
      console.log("fetchData 1");
      const requestOptions = {
        mode: 'cors',
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }/*,
        body: JSON.stringify({ 
          "recipient_address": "2MuyPqzyWiUYATUAGqFVQjVpb5fwak37jTp",	
          "amount": 2200,
         												
         })*/
    };

    
    //let url=  "http://172.22.23.22:8000/paymentvalues/"+payment_ref_id;  
      let url=  urlApi.paymentvalues+"/"+payment_ref_id;
      console.log("url",url);
      fetch(url,requestOptions)
      //.then(response => response.json())
      //.then(data => this.setState({ postId: data.id }));
      //console.log("newData",newData);

      .then(async response => {
        
        
        const data = await response.json();
        console.log("data",data); 
       
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
        }
        //data.customer_has_wallet = true;
        //data.validity_duration = 10000;
        

        if( data.status === "200" || data.status === "201" ){
          setData({...data});
          setLoading(false);
          setError("");
          setlistUrl(
            {
              "details" : 
               [
                { "url":data.notif_url,"text":"page 1 - notif"},
                { "url":data.return_url,"text":"page 2 - return"},
                { "url":data.cancel_url,"text":"page 3 - cancel"}
               ]
            }
          )          
        }else{
          setLoading(false);
          setError(data.message);
        }
        
    })
    .catch(error => {
      //console.log("error fetchData",error);
      setLoading(false);  
      setError(error.toString());
       
    })
    //.finally(() => { alert('ici'); } )
    ;


    };

    fetchData();
    
    
  }, [payment_ref_id]);


  if (loading) {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (<Container><div style={style}><Spinner animation="border" role="status">
    <span className="visually-hidden">En&nbsp;cours&nbsp;de&nbsp;chargement&nbsp;...</span>
  </Spinner></div></Container>);

  }


  if (error!=='') {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (<Container><br/><div style={style}><Alert variant="danger">
      {error}
    </Alert></div></Container>);

  }

  if(data!==null){

    
    

      return (
        <div>
            
            

                      

            <Form>
    
            <Container fluid>
            <ModalCodePin/>
            <RedirectPage/>

            {/*<Row>
              <Col>Debug : {payment_ref_id}</Col>
          </Row>*/}

            <Row>
              <Col className='sub_nav'>
              <h1>Réglèment en crypto-monnaie</h1>
              </Col>
            </Row>
            <Row>
              <Col className='info_user'>
              <h3>{util.isNull(data.beneficiary,"")}</h3>
              </Col>
            </Row>

            <Row>
            <Col>
            <Alert key="success" variant="success">
                Temps restants pour valider la transaction :<br/>
                <CountDown onComplete={redirectUrlC} redirectUrlC={redirectUrlC} ms={util.isNull(data.validity_duration,0)}/>
            </Alert>
            </Col>  
            </Row>      

            <Row>

              <Col xs={12} sm={6}  className='mb-2'>

                <Card>
                    <Card.Body>
                      <Card.Title>Information de la transaction</Card.Title>
                      <Card.Text>
                      <Row>
                        <Col>Total de la commande : </Col><Col>{util.isNull(data.amount_fiat,0)}&nbsp;{util.isNull(data.fiat,'--')}
                        &nbsp;/&nbsp;{util.isNull(data.crypto,'?')}&nbsp;:&nbsp;{util.isNull(data.amount_crypto,0)}</Col>
                        </Row>
                       <Row>
                        <Col>Adresse de réglement : </Col><Col>XXXXXXXXXXXXXXXXXX</Col>
                      </Row>
                        <AffQr/>
                      </Card.Text>
                  
                    </Card.Body>
                  </Card>
                    

                

                </Col>
        
              <Col  className='mb-2'>

              <Card>
                  <Card.Body>
                    <Card.Title>Remarque important</Card.Title>
                    <Card.Text>
                      <b>Instruction</b><br/>
                        La confirmation du réglement peut prendre 20 minutes ou plus après avoir scanné le QR Code.
                        <br/>
                        <br/>
                        Vous pouvez quitter cet écran sans attendre la confirmation, un mail vous sera envoyé lorsque le réglement sera définitivement validé.
                        <br/>
                        
                        <Row><Col>
                        
                        <EtatReglement/>
                        
                        
                        </Col></Row>                        
                    </Card.Text>
                
                  </Card.Body>
                </Card>


              </Col>

            </Row>    

            
            <Row>
            <Col className='text-center'>
            
            
            {data.customer_has_wallet ?
                ( <Button variant="primary" type="button" onClick={handleShowModalPin}  disabled={etatTraitement.disabledBtnRg?'disabled':''}>
                    Effecuter le réglement
                </Button> )
                
            :
              <Button variant="primary" type="button" onClick={()=> redirectUrl(1)}  disabled={etatTraitement.disabledBtnCart?'disabled':''}>
              Revenir au site marchant
          </Button>
            }
            
                &nbsp;&nbsp;
                <Button variant="primary" type="button" onClick={()=> redirectUrl(2)}  disabled={etatTraitement.disabledBtnCart?'disabled':''}>
                  Revenir au panier
                </Button>
                </Col>
             
            </Row>
    
    
            </Container>
    
            </Form>
    
        </div>
      );
    }
  }
  
  export default Payment;