const baseUrl = "https://api.letitcoin.com";
//const baseUrl = "http://172.22.23.22:8000";
module.exports = Object.freeze({


//Payment
updatedtransactionstatus : `${baseUrl}/updatedtransactionstatus`,
transactionandbroadcast : `${baseUrl}/transactionandbroadcast`,
paymentvalues : `${baseUrl}/paymentvalues`,

//List Operation
operationsmonthownerid : `${baseUrl}/operationsmonthownerid`,

//Receiving
walletnextavailableaddress : `${baseUrl}/walletnextavailableaddress`,
receiveqrcode : `${baseUrl}/receiveqrcode`,

//Send
balanceownerid : `${baseUrl}/balanceownerid`,
transactionandbroadcastownerid : `${baseUrl}/transactionandbroadcastownerid`,

//Multipe sceen
checkownerpin : `${baseUrl}/checkownerpin`

});