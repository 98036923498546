import React from "react";


const CountDown = ({ ms, onComplete }) =>  {
    //console.log("ms",props.ms)
    const [counter, setCounter] = React.useState(ms);


   /* React.useEffect(() => {
        console.log("use Effect CountDown");
        if(complete && isFirstCall){
            redirectUrlC();
            isFirstCall.current=false;
        }
    },[complete,redirectUrlC]);*/
   
    function toMMSS (ms){
        //console.log("sec",ms)
        var ms_to_sec = parseInt(ms, 10)/1000;       
        var sec_num = parseInt(ms_to_sec, 10); 
        var hours   = Math.floor(sec_num / 3600);
        var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
        var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
        //if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        return minutes+':'+seconds;
    }

    React.useEffect(() => {
        //console.log("counter",counter);
        //console.log("props.ms 2",props.ms);
        const timer =
        counter > 0 && setInterval(() => setCounter((counter) - 1000), 1000);
        if(timer!==null && counter===0){
            console.log("counter return 0"); 
           // redirect();
         //  setComplete(true);
         if (typeof onComplete === "function") {
            onComplete();
          }

        }
        return () => { clearInterval(timer);  };
                
      }, [counter]); //,props

      

      return (
        <div>
            {toMMSS(counter)}
        </div>
      );
    
  }
  
  export default CountDown;