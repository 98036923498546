import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

import React from "react";


const confirmPin = () =>  {
    
  

      return (
        <div>
            
            <h1>Code PIN</h1>
            <h3>Veuillez saisir le code PIN de confirmation</h3>    

            
            <Form>
    
            <Container>
                  

            <Row>
            {[1,2,3,4,5,6].map((l, i) => {
               return <Col><InputGroup className='mb-3'>
                              <InputGroup.Text id={'pin'+i}>#</InputGroup.Text>
               <Form.Control
                 
               />
             </InputGroup></Col>
            })}
            </Row>
    
    
            </Container>
    
            </Form>
    
        </div>
      );
    
  }
  
  export default confirmPin;