import Container from 'react-bootstrap/Container';
import React from "react";
import QrCodeScan from '../inc/QrCodeScan'

const ScanCode = (props) => {

    const onNewScanResult = (decodedText, decodedResult) => {
        // handle decoded results here
    };

    return (
        <Container fluid>
            <QrCodeScan
                fps={20}
                qrbox={250}
                onResult={(resText,resResult) => onNewScanResult(resText,resResult)}
            />
        </Container>
    );
};

export default ScanCode;