import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';

import React from "react";
import { useState, useEffect } from 'react'; //, useCallback

import util from '../inc/Utils';
import urlApi from '../inc/UrlApi';

import PopupRetour from '../inc/PopupRetour';
import PopupWait from '../inc/PopupWait';

/*import {QRCodeSVG} from 'qrcode.react';*/

import { useLocation  } from "react-router-dom"; /*, useNavigate*/

const Send = () =>  {

  const location = useLocation();
  //const navigate = useNavigate();
  
  let customer_id = util.isNull(new URLSearchParams(location.search).get("customer_id"),"ba8086f4-0c28-11ee-8252-5ebe302cd670");
  let coin  = util.isNull(new URLSearchParams(location.search).get("coin"),"1");
  

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [solde, setSolde] = useState(0.0);
  const [label, setLabel] = useState("");
  const [montant, setMontant] = useState("");
  const [namesolde, setNamesolde] = useState("");
  const [error, setError] = useState('');

  const [popup, setPopup] = useState( {bgt_popup:"", titre_popup:"", msg_popup:"", show_popup:false}  );
  //const [popup, setPopup] = useState( {bgt_popup:"success", titre_popup:"Leticoin", msg_popup:"Transaction effecutée", show_popup:true}  );
  //const [popup, setPopup] = useState( {bgt_popup:"danger", titre_popup:"Leticoin", msg_popup:"Transaction Nok", show_popup:true}  );
  const [popupW, setPopupW] = useState(  {show_popup:false}  );
  //const [popupW, setPopupW] = useState(  {show_popup:true}  );

  
  
  const [showModalPin, setShowModalPin] = useState(false);
  const [errPin, setErrPin] = useState("");

  const [values, setValues] = useState({ });
  const [formerrors, setFormErrors] = useState({}); 
  
  const handleChange = (event) => {
      
      setValues((values) => ({
        ...values,
      [event.target.name]: event.target.value,
      }));
    }
  
     const validate = () => {
        console.log("Validate the form....");
    
        let errors = {};
    
        if (!values.adress) {
          errors.adress = "Veuillez saisir une adresse";
        }

        if (!values.label) {
          errors.label = "Veuillez saisir un libellé";
        }else{
          setLabel(values.label);
        }

        if (!values.amount) {
          errors.amount = "Veuillez saisir un montant";
        }else{
          setMontant(values.amount);
        }
        
    
        //console.log("checkedItems",checkedItems);
        console.log("errors",errors);
        setFormErrors(errors);
    
        if (Object.keys(errors).length === 0) {
          return true;
        } else {
          return false;
        }
      };
    
      const handleSubmit = (event) => {
        if (event) event.preventDefault();
        
        var r = validate(values);
        //console.log("handleSubmit r",r);
        if(r){
          //doSend(customer_id,coin);
          handleShowModalPin();
        }
        
        return(r);
      };

 
  const fetchData = async (customer_id,coin) => {
      
    console.log("fetchData 1");
    const requestOptions = { mode: 'cors',  method: 'GET',   headers: { 'Content-Type': 'application/json' } };
    
    //let url="http://172.22.23.22:8000/balanceownerid/"+customer_id+"/"+coin;
    let url=urlApi.balanceownerid+"/"+customer_id+"/"+coin;
    console.log("url",url);
    fetch(url,requestOptions)
    

    .then(async response => {
      
      
      const data = await response.json();
      console.log("data",data); 
     
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
      }
      

      if( data.status === "200" || data.status === "201" ){
        setData({...data});
        setSolde( data.balance_crypto+" "+data.crypto +" / "+ data.amount_fiat+" "+data.fiat );
        setNamesolde(data.name);
        setLoading(false);
        setError("");
      }else{
        setLoading(false);
        setError(data.message);
      }
      
  })
  .catch(error => {
    console.log("error fetchData",error);
    setLoading(false);  
    setError(error.toString());
  })
  //.finally(() => { alert('ici'); } )
  ;


  };

  useEffect(() => {
    fetchData(customer_id,coin);
    
  }, [customer_id,coin]);


  const doSend = async (customer_id,coin) => {
    
    setPopup({bgt_popup:"", titre_popup:"", msg_popup:"", show_popup:false});
    setPopupW({show_popup:true});

    console.log("doSend",values.adress,util.toInt(values.amount) );
    const requestOptions = { mode: 'cors',  method: 'POST',   headers: { 'Content-Type': 'application/json' } ,
    body: JSON.stringify({ 
      "recipient_address": values.adress,
      "amount": util.toInt(values.amount)
     })
    };
    
    
    //let url="http://172.22.23.22:8000/transactionandbroadcastownerid/"+customer_id+"/"+coin;
    let url=urlApi.transactionandbroadcastownerid+"/"+customer_id+"/"+coin;
    console.log("url",url);
    fetch(url,requestOptions)
    

    .then(async response => {
      
      
      const data_t = await response.json();
      console.log("data_t",data_t); 
     
      // check for error response
      if (!response.ok) {
          // get error message from body or default to response status
          const error = (data_t && data_t.message) || response.status;
          return Promise.reject(error);
      }
      
      setPopupW({show_popup:false});
      if( data_t.status === "200" || data_t.status === "201" ){
        console.log("doSend ok");
        setPopup({bgt_popup:"success", titre_popup:"Leticoin", msg_popup:"Transaction effecutée", show_popup:true});
      }else{
        console.log("doSend nok");
        setPopup({bgt_popup:"danger", titre_popup:"Leticoin", msg_popup:"Transaction Nok", show_popup:true});
      }
      
  })
  .catch(error => {
    console.log("error doSend",error);
    setPopup({bgt_popup:"danger", titre_popup:"Leticoin", msg_popup:error, show_popup:true});
  })
  //.finally(() => { alert('ici'); } )
  ;


  };

  /* CODE PIN - début */
  const handleCloseModalPin = () => {setShowModalPin(false); };
  
  const handleOkModalPin = () => {
    console.log("handleOkModalPin");
      let p = customer_id;
      for(var i=1;i<=6;i++){
        var val = document.getElementById("pin"+i).value;
        val =  (val.length===0?"0":val);
        p+="/"+val;
      }                            
      console.log("p",p);

      const requestOptions = {
        mode: 'cors',
        method: 'GET',
        //headers: { 'Content-Type': 'application/json' },
        
    };
    
      //let url="http://172.22.23.22:8000/checkownerpin";
      let url=urlApi.checkownerpin;
      url+="/"+p;
      console.log("url",url);
      fetch(url,requestOptions)
      
      .then(async response => {
        
        
        const data_j = await response.json();
        console.log("data_j",data_j); 
       
        // check for error response
        if (!response.ok) {
            // get error message from body or default to response status
            const error = (data_j && data_j.message) || response.status;
            return Promise.reject(error);
        }

        if( data_j.status === "200" ){
          setShowModalPin(false);
          doSend(customer_id,coin);
          
        }else{
          setErrPin(data_j.message);
        }
        
    })
    .catch(error => {
      console.log("error handleCloseModalPin",error);
      setErrPin(error.toString());
      
       
    })
    //.finally(() => { alert('ici'); } )
    ;
    
    
    

  }

  const selectPin = (event) => {
    const f = event.target;
    f.select();
  }

  const codePinChangeHandler = (event) => {
    const element = event.target;
    //console.log("element",element);
    const next = document.getElementById("pin"+(parseInt(element.id.replace("pin",""),10)+1));
    //console.log("next",("pin"+(parseInt(element.id.replace("pin",""),10)+1)),next);
    next ? next.focus() : element.blur();

    let cpt=0;
    for(let i=1;i<=6;i++){
      cpt += (parseInt( (document.getElementById("pin"+i).value!==''?1:0),10));
    }
    //console.log("cpt",cpt);
    if(cpt===6){
      handleOkModalPin();
     
    }

  };

  

  const handleShowModalPin = () => {setShowModalPin(true);}
  //const [dataPin, setDataPin] = useState(null);

  function ModalCodePin(){
    return (
      <>
        
  
        <Modal centered show={showModalPin} onHide={handleCloseModalPin}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation Code PIN</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Row>
            {[1,2,3,4,5,6].map((l) => {
               return <Col><InputGroup className='mb-1'>
               <Form.Control
                 type="number"
                 id={'pin'+l}
                 maxLength={1}
                 onFocus={(event) => selectPin(event)}
                 onChange={(event) => codePinChangeHandler(event)}
               />
             </InputGroup></Col>
            })}
            </Row>
            {errPin  &&
            <Row>
              <Col>
              <br/>
              <Alert variant="danger">
              {errPin}
              </Alert>
              </Col>
            </Row>
          }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary"  type="button" onClick={handleOkModalPin}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  /* CODE PIN - fin */
  

  if (loading) {
    const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
    return (<Container><div style={style}><Spinner animation="border" role="status">
    <span className="visually-hidden">En&nbsp;cours&nbsp;de&nbsp;chargement&nbsp;...</span>
  </Spinner></div></Container>);

  }


      return (
        

        <Container fluid>
          <Form onSubmit={handleSubmit}>
    
           
              <ModalCodePin/>
              <PopupRetour bgt_popup={popup.bgt_popup} titre_popup={popup.titre_popup} msg_popup={popup.msg_popup} show_popup={popup.show_popup} />
              <PopupWait show_popup={popupW.show_popup} />

            <Row>
              <Col className='sub_nav'>
              <h1>&#8679;&nbsp;Envoie</h1>
              </Col>
            </Row>
            <Row>
              <Col sm="auto" className='info_btk'>
              <h4>Solde {namesolde} </h4><h6>{solde}</h6>
            </Col>
            </Row>
            <Row>
              <Col>
              {error!==''?
            <div><Alert variant="danger">
              {error}
            </Alert></div>
            :""}
              </Col>
            </Row>

            
            
            

            <Row>

            
            <Form.Label htmlFor="adresse">Adresse</Form.Label>
            <InputGroup className="mb-3">
                {/*<InputGroup.Text id="basic-addon1">@</InputGroup.Text>*/}
                <Form.Control
                placeholder="Saisir une adresse"
                name="adress"
                id="adress"
                isInvalid={ !!formerrors.adress } onChange={handleChange}
                />
                {formerrors.adress && (<Form.Control.Feedback type="invalid">
                        {formerrors.adress}
                    </Form.Control.Feedback>)}
            </InputGroup>
            
          </Row>

          <Row>
            <Form.Label htmlFor="label">Libellé</Form.Label>
            <InputGroup className="mb-3">
                 {/*<InputGroup.Text id="basic-addon1">[A-Z/1-9]</InputGroup.Text>*/}
                <Form.Control
                placeholder="Saisir une libellé"
                name="label"
                id="label"
                isInvalid={ !!formerrors.label } onChange={handleChange}
                />
                {formerrors.label && (<Form.Control.Feedback type="invalid">
                        {formerrors.label}
                    </Form.Control.Feedback>)}
            </InputGroup>
            </Row>

            <Row>
            <Form.Label htmlFor="montant">Montant</Form.Label>
            <InputGroup className="mb-3">
                 {/*<InputGroup.Text id="basic-addon1">[1-9]</InputGroup.Text>*/}
                <Form.Control
                type="number"
                placeholder="Saisir un montant"
                name="amount"
                id="amount"
                isInvalid={ !!formerrors.amount } onChange={handleChange}
                />
                {formerrors.amount && (<Form.Control.Feedback type="invalid">
                        {formerrors.amount}
                    </Form.Control.Feedback>)}
            </InputGroup>
            </Row>

            

          <Row><Col className='text-center d-grid'>
            <Button variant="primary" type="submit">
                  Envoyer
                </Button>
      </Col></Row>
            </Form>
    
            </Container>
      );
   // }
  }
  
  export default Send;